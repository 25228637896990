export default {
  heading: 'Oooh Baby, cursive',
  subheading: 'Source Sans Pro, sans-serif',
  body: 'Cormorant Garamond, serif',
  monospace: 'Menlo, monospace',
  display: 'Oooh Baby, cursive',
  googleFonts: [
    'Source Sans Pro:200,300,400,500,600,700,800,900',
    'Handlee',
    'Cormorant Garamond:100,200,300,400,500,600,700,800,900',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Playfair Display:100,200,300,400,500,600,700,800,900',
    'Oooh Baby'
  ],
  customFamilies: ['Amelie', 'Corinthian Light', 'Futura'],
  customUrls: [
    'https://www.gonation.biz/fonts/amelie/amelie.css',
    'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
    'https://www.gonation.biz/fonts/futura/futura.css'
  ]
}
