export default {
  text: '#8e8e8e',
  primary: '#461c32',
  primaryLight: '#573c5a',
  secondary: '#ababab',
  brandGrey: '#566464',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
